@import "../../common/theme_variables";
@import "../../mixins/transitions";
@import "../../mixins/shadows";

.after-nav{
    position: relative;
}

#search_block_top{
    display: none;
    border-top:$golden solid 4px; 
    padding: 50px 0;
    background: $grey;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 102;
    width: 100%;
    @include shadows(0,2px,0,0px,rgba(0,0,0,0.2));

    &.open{
        display: block;
    }

    form{
        position: relative;

        #search_query_top{
            background: none;
            border:none;
            border-bottom: lighten($black,65%) solid 1px;
            font-size: 36px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 60px;
            width: 100%;
        }

        button{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            padding-top: 10px;
            padding-bottom: 10px;
            background: none;
            border:none;
            cursor: pointer;
            font-size: 24px;
        }
    }
    
}